/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * '.alert' shows at top of page
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.alert {
	position: relative;
	max-width: $layout-max-width;
	margin-top: -55px;
	margin-bottom: 50px;
	background-color: black;
	color: white;
	z-index: 2;
	.block {
		display: flex;
		flex-wrap: wrap;
		padding: 30px 0;
		&::before {
			content: '';
			display: block;
			flex-basis: 52px;
			width: 52px;
			height: 48px;
			margin: 0 15px 0 0;
			background: url('svg/alert-icon.svg') no-repeat center transparent;
			background-size: contain;
		}
	}
	.inner {
		flex: 1 0 0;
	}
	h2 {
		margin: 0 0 12px;
		padding-right: 40px;
		font: $semi-bold 3rem/lh(30, 32) $poppins;
	}
	.info {
		font: 1.8rem/lh(18, 28) $poppins;
	}
	p {
		margin: 0 0 1em;
	}
	.buttons {
		flex-basis: 100%;
		margin: 0;
	}
	.btn {
		margin: 0 auto;
	}
	.close {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 20px;
		height: 20px;
		background: url('svg/icon-close-x-white.svg') no-repeat center transparent;
		background-size: contain;
		border: 0;
		cursor: pointer;
	}
}

@media (--medium) {
	.alert {
		margin-top: -55px;
		margin-bottom: 55px;
	}
}

@media (--large-up) {
	.alert {
		margin-top: -80px;
		margin-bottom: 80px;
		font-size: 2.6rem;
		line-height: lh(26, 40);
		.block {
			padding: 50px 0;
			&::before {
				flex-basis: 113px;
				width: 113px;
				height: 104px;
				margin: 0 30px 0 0;
			}
		}
		h2 {
			padding: 0;
			font-size: 5rem;
			line-height: lh(50, 77);
		}
		.buttons {
			justify-content: flex-start;
		}
		.btn {
			margin: 0 0 0 143px;
		}
		.close {
			top: 30px;
			right: 30px;
			width: 30px;
			height: 30px;
		}
	}
}
