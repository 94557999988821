/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for carousels
 * @module: .carousel
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

$carousel-img-height-sm: 165px;
$carousel-img-height-md: 330px;
$carousel-img-height-lg: 540px;

.frozen-carousels {
	.carousel,
	.carousel-sm {
		.track {
			transition: none !important; // scss-lint:disable ImportantRule
		}
	}
}


// THIS FEELS REALLY GROSS TO ME, BUT I CAN'T FIND ANOTHER FIX FOR THIS
.pattern-carousel .gutter {
	max-width: 1400px;
	@media (--medium) {
		width: calc(100vw - resolve(($side-margin-medium * 2) + $nav-bar-width-medium));
	}
	@media (--large-up) {
		width: calc(100vw - resolve(($side-margin-large * 2) + $nav-bar-width-large));
	}
}

.carousel {
	position: relative;
	.gutter {
		position: relative;
		// background-color: #a6192e;
		background-color: $red;
		z-index: 1;
	}
	.track {
		overflow: hidden;
		display: flex;
		position: relative;
	}
	.slide {
		.media {
			position: relative;
		}
		img {
			width: 100%;
			height: $carousel-img-height-sm;
			object-fit: cover;
		}
		.text {
			margin-top: -1px;
			padding: 13px;
			// background-color: #a6192e;
			background-color: $red;
			color: white;
			font: $light 1.4rem/lh(14, 24) $poppins;
			p:last-of-type {
				margin: 0;
			}
		}
	}
}
.carousel-indices {
	display: flex;
	justify-content: center;
	position: absolute;
	top: resolve($carousel-img-height-sm - 10px - 19px);
	right: 32px;
	left: 32px;
	margin: 0;
	padding: 0;
	list-style: none;
	pointer-events: none;
	z-index: 2;
	a {
		margin: 0 12px;
		padding: 5px;
		cursor: pointer;
		pointer-events: all;
		&.on {
			cursor: default;
			// pointer-events: none;
			.dot {
				// background-color: #a6192e;
				background-color: $red;
			}
		}
	}
	.dot {
		width: 9px;
		height: 9px;
		background-color: white;
		border: 2px solid white;
		border-radius: 100%;
	}
}
.carousel-nav {
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: resolve($carousel-img-height-sm - 10px - 20px);
	right: 5px;
	left: 5px;
	pointer-events: none;
	z-index: 2;
	.item {
		padding: 0 5px;
		cursor: pointer;
		pointer-events: all;
		&::after {
			content: '';
			display: block;
			width: 12px;
			height: 20px;
			background: url('svg/arrow-left-white.svg') no-repeat center transparent;
			background-size: contain;
		}
		&.next {
			transform: rotate(180deg);
		}
	}
}

@media (--medium) {
	.carousel {
		.carousel-indices {
			top: resolve($carousel-img-height-md - 12px - 5px - 16px);
			a {
				margin: 0 5px;
			}
			.dot {
				width: 12px;
				height: 12px;
			}
		}
		.slide {
			img {
				height: $carousel-img-height-md;
			}
			.text {
				padding: 15px;
				font: $light 1.5rem/lh(15, 25) $poppins;
			}
		}
		.carousel-nav {
			top: resolve(($carousel-img-height-md / 2) - 12px);
			right: 10px;
			left: 10px;
			.item {
				&::after {
					width: 15px;
					height: 25px;
				}
			}
		}
	}
}

@media (--large-up) {
	.carousel {
		.carousel-indices {
			top: resolve($carousel-img-height-lg - 17px - 5px - 24px);
			a {
				margin: 0 12px;
			}
			.dot {
				width: 17px;
				height: 17px;
			}
		}
		.slide {
			img {
				height: $carousel-img-height-lg;
			}
			.text {
				padding: 20px 40px;
				font: $light 1.6rem/lh(16, 28) $poppins;
			}
		}
		.carousel-nav {
			top: resolve(($carousel-img-height-lg / 2) - 20px);
			right: 25px;
			left: 25px;
			.item {
				&::after {
					width: 24px;
					height: 40px;
				}
			}
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Style adjusments for small carousels only
 * @module: .carousel-sm
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

@media (--small) {
	.carousel-sm {
		display: flex;
		flex-direction: column;
		margin-right: 10px;
		margin-left: 10px;
		> * {
			order: 1;
		}
		.gutter {
			overflow: visible !important; // scss-lint:disable ImportantRule
			display: block;
			position: relative;
			z-index: 1;
		}
		.track {
			overflow: hidden;
			display: flex;
			position: relative;
		}
		.slide {
			margin: 0 5px;
		}
		.carousel-indices {
			order: 2;
			position: static;
			margin: 25px 0 0;
			a {
				&.on {
					.dot {
						background-color: white;
					}
				}
			}
			.dot {
				background-color: $red;
				border: 2px solid $red;
			}
		}
		.carousel-nav {
			order: 3;
			position: relative;
			top: -19px;
			right: auto;
			left: auto;
			margin-right: -10px;
			margin-left: -10px;
			.item {
				&::after {
					background-image: url('svg/arrow-left-red.svg');
				}
			}
		}
	}
}
@media (--medium-up) {
	.carousel-sm {
		.carousel-indices,
		.carousel-nav {
			display: none;
		}
		// scss-lint:disable ImportantRule
		.gutter {
			overflow: visible !important;
			cursor: default !important;
		}
		.track {
			width: auto !important;
			transform: none !important;
			transition: none !important;
		}
		.slide {
			float: none !important;
			width: auto !important;
		}
		// scss-lint:enable ImportantRule
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for hero carousel
 * @module: .hero-carousel
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.hero-carousel {
	position: relative;
	margin-top: resolve(-50px - $sub-nav-bar-height);
	margin-right: -$side-margin-small;
	margin-left: -$side-margin-small;
	padding: 0;
	@media (--medium) {
		margin-right: -$side-margin-medium;
		margin-left: -$side-margin-medium;
	}
	@media (--large-up) {
		margin-right: -$side-margin-large;
		margin-left: -$side-margin-large;
	}
	.slide {
		position: relative;
		.content {
			color: white;
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0, transparent 90%);
				z-index: 2;
			}
		}
		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: 100%;
			z-index: 1;
		}
		.overlay {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			position: relative;
			// min-height: 520px;
			height: 520px;
			padding: 15px 15px 50px;
			z-index: 3;
			> * {
				max-width: 100%;
			}
		}
		h2 {
			margin: 0 0 percent(25 / 895);
			font: $semi-bold 3.5rem/lh(35, 45) $poppins;
			text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
			opacity: 0;
		}
		.btn {
			margin: 0;
			opacity: 0;
		}
		&:first-child {
			.content::after {
				display: none;
			}
			.overlay {
				display: block;
				padding: 0;
			}
			h1 {
				overflow: hidden;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 320px;
				height: 42px;
				margin: -21px 0 0 -160px;
				padding: 0 15px;
				background-color: rgba(166, 25, 46, 0.8);
				transition: clip-path 0.6s ease-in-out 0.2s;
				clip-path: inset(50% 0 50% 0);
				&::after {
					content: '';
					display: block;
					width: 291px;
					height: 13px;
					background: url('svg/sbcc-slide-words.svg') no-repeat center transparent;
					background-size: contain;
					transition: opacity 1.2s ease-in-out 0.2s;
					opacity: 0;
				}
			}
		}
		&.on {
			h2 {
				transition: opacity 0.25s ease-in-out 0.25s;
				opacity: 1;
			}
			.btn {
				transition: opacity 0.25s ease-in-out 0.75s;
				opacity: 1;
			}
			&:first-child {
				h1 {
					clip-path: inset(0 0 0 0);
					&::after {
						opacity: 1;
					}
				}
			}
		}
		&.cease-transitions {
			h2 {
				transition: none;
			}
			.btn {
				transition: none;
			}
			&:first-child {
				h1 {
					transition: none;
					&::after {
						transition: none;
					}
				}
			}
		}
	}
	.carousel-indices {
		top: auto;
		bottom: 15px;
	}
	.carousel-nav {
		top: auto;
		bottom: 15px;
	}
}

@media (--medium) {
	.hero-carousel {
		margin-top: resolve(-55px - $sub-nav-bar-height);
		.slide {
			.overlay {
				// min-height: 600px;
				height: 600px;
				padding: 25px 100px 60px 25px;
			}
			h2 {
				font-size: 5.2rem;
			}
			&:first-child {
				h1 {
					width: 504px;
					height: 65px;
					margin: -32px 0 0 -252px;
					padding: 0 25px;
					&::after {
						width: 454px;
						height: 19px;
					}
				}
			}
		}
	}
}

@media (--large-up) {
	.hero-carousel {
		margin-top: resolve(-80px - $sub-nav-bar-height);
		.slide {
			.overlay {
				// min-height: 700px;
				height: 700px;
				padding: 60px 250px 60px 55px;
			}
			h2 {
				font-size: 6.5rem;
			}
			.btn {
				padding: 22px 50px;
				font: $semi-bold 2.2rem/1.4 $poppins;
			}
			&:first-child {
				h1 {
					width: 630px;
					height: 82px;
					margin: -41px 0 0 -315px;
					padding: 0 30px;
					&::after {
						width: 571px;
						height: 25px;
					}
				}
			}
		}
		.carousel-nav {
			top: 50%;
			right: 5px;
			bottom: auto;
			left: 5px;
			margin-top: -20px;
		}
	}
}
