/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Events List

 * Page displays all Event objects in chronological
 * order, starting with today's date. 10 objects are
 * displayed per page. If a user reaches an Event
 * list page by clicking on a tag link from a
 * tag-based 3 Up Feature display object, underneath
 * the page title a subtitle will read "Results For:
 * [TAG NAME]" with a link to view all events.
 * @module: .events-list
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.events-list-results {
	margin: 15px 0 95px;
	.results {
		margin: 0 0 20px;
		font: 1.8rem/lh(18, 28) $poppins;
	}
	.btn {
		display: inline-block;
		margin: 0;
		cursor: pointer;
	}
}
.events-list {
	margin-top: 50px;
	.media-block {
		display: flex;
		margin: 0 0 40px;
	}
	.date-block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		height: 60px;
		margin-right: 16px;
		border: 3px solid #cacaca;
	}
	.db-month {
		margin: 0 0 5px;
		font: $semi-bold 1rem/1 $poppins;
		text-transform: uppercase;
	}
	.db-date {
		font: $semi-bold 2.4rem/0.85 $poppins;
	}
	.date {
		margin: 0 0 10px;
		font: $semi-bold 1.2rem/lh(12, 17) $poppins;
		// text-transform: uppercase;
	}
}
@media (--medium) {
	.events-list-results {
		display: flex;
		align-items: center;
		margin: 30px 0 80px;
		.results {
			margin: 0 50px 0 0;
			font: 2rem/lh(20, 30) $poppins;
		}
	}
	.events-list {
		.date-block {
			flex: 0 0 112px;
			height: 112px;
			margin-right: 30px;
		}
		.db-month {
			margin: 0 0 7px;
			font-size: 2rem;
		}
		.db-date {
			font-size: 5rem;
		}
	}
}
@media (--large-up) {
	.events-list-results {
		display: flex;
		align-items: center;
		margin: 35px 0 150px;
		.results {
			margin: 0 65px 0 0;
			font: 2.6rem/lh(26, 40) $poppins;
		}
	}
	.events-list {
		.date-block {
			flex: 0 0 150px;
			height: 150px;
			margin-right: 30px;
		}
		.db-month {
			margin: 0 0 10px;
			font-size: 2.6rem;
		}
		.db-date {
			font-size: 7.5rem;
		}
		.date {
			margin: 0 0 16px;
			font: $semi-bold 1.6rem/lh(16, 23) $poppins;
		}
	}
}
