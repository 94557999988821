/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Section displaying a list of all subject areas
 * in order to help site visitors narrow down the
 * programs they wish to explore.
 * @module: .subjects
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.subjects {
	ul {
		display: flex;
		flex-wrap: wrap;
		margin: -3px -3px 60px;
		padding: 0;
		font: $semi-bold 1.3rem/lh(13, 16) $poppins;
		list-style: none;
		// word-break: break-word;
	}
	li {
		flex: 0 0 50%;
		a {
			display: flex;
			align-items: center;
			height: 60px;
			margin: 3px;
			padding: 0 10px;
			background-color: white;
			color: $red;
			text-decoration: none;
			&:hover {
				color: $dark-red;
				span::after {
					transform: translateX(10px);
					filter: brightness(0.7);
				}
			}
		}
		span {
			display: inline-block;
			position: relative;
			padding-right: 12px;
			white-space: nowrap;
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 0;
				width: 8px;
				height: 6px;
				margin: -3px 0 0;
				background: url('svg/arrow-red-2.svg') no-repeat center transparent;
				background-size: contain;
				transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
			}
		}
		&:last-child {
			a {
				background-color: $red;
				color: white;
				-webkit-font-smoothing: antialiased;
				transition: background-color 0.3s ease-in-out;
				&:hover {
					background-color: $dark-red;
					span::after {
						filter: none;
					}
				}
			}
			span {
				&::after {
					background-image: url('svg/arrow-white-2.svg');
				}
			}
		}
	}
}
@media (--medium-up) {
	.subjects {
		ul {
			font-size: 1.6rem;
			line-height: lh(16, 20) $poppins;
		}
		li {
			flex-basis: 33%;
			a {
				height: 80px;
				padding: 0 12px;
			}
			span {
				padding-right: 16px;
				&::after {
					width: 11px;
					height: 8px;
				}
			}
		}
	}
}
@media (width >= 1130px) {
	.subjects {
		ul {
			margin: -10px -10px 60px;
			font-size: 2.2rem;
			line-height: lh(22, 26);
		}
		li {
			flex-basis: 25%;
			max-width: 25%;
			a {
				height: 110px;
				margin: 5px;
				padding: 0 18px;
			}
			span {
				padding-right: 22px;
				&::after {
					width: 15px;
					height: 11px;
				}
			}
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: .cta-2col-bg
 * Section featuring key content for prospective
 * audiences that differentiates SBCC as an
 * institution.Uses a CTAs: 2 Columns, Background
 * display object; author can opt to add additional
 *  display objects to feature other content.
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.cta-2col-bg {
	> h2 {
		margin: 0 0 20px;
	}
	.items {
		width: 100%;
		color: white;
	}
	.mask {
		margin: 0 0 10px;
		padding-bottom: percent(390 / 580);
	}
	.img-wrap {
		background-color: black;
	}
	.item {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: percent(40 / 580);
		text-align: center;
		> * {
			width: 100%;
		}
	}
	h3 {
		margin: 0 0 13px;
		font: $semi-bold 2.6rem/lh(26, 34) $poppins;
		text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
	}
	p {
		margin: 0 0 22px;
		font: 1.7rem/lh(17, 27) $poppins;
		text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
	}
	.btn {
		margin: 0;
	}
}
@media (--small) {
	.cta-2col-bg {
		.mask {
			margin: 0 0 50px;
			padding: 0;
			&::after {
				display: none;
			}
		}
		.mask-img {
			display: block;
			position: static;
			width: 100%;
			height: auto;
			margin: 0 0 30px;
		}
		.item {
			position: relative;
			padding: 0;
		}
		h3 {
			color: $red;
			font: $semi-bold 2rem/lh(20, 32) $poppins;
			text-shadow: none;
		}
		p {
			color: $text-color;
			font: 1.7rem/lh(17, 27) $poppins;
			text-shadow: none;
		}
		.btn {
			font: $semi-bold 1.5rem/lh(15, 22) $poppins;
		}
	}
}
@media (--medium) {
	.cta-2col-bg {
		> h2 {
			margin: 0 0 35px;
		}
	}
}
@media (--large-up) {
	.cta-2col-bg {
		> h2 {
			margin: 0 0 45px;
		}
		.items {
			position: relative;
			padding-bottom: percent(275 / 824);
		}
		.items-wrap {
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.mask {
			flex: 1 0 0;
			width: 100%;
			margin: 0;
			padding: 0;
			&:first-child {
				margin-right: 10px;
			}
		}
		h3 {
			margin: 0 0 17px;
			font: $semi-bold 3.2rem/lh(32, 34) $poppins;
		}
		p {
			margin: 0 0 32px;
			font: 1.9rem/lh(19, 27) $poppins;
		}
		.wrap {
			margin: 0 percent(-40 / 580);
		}
	}
}
