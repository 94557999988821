/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Main skip to content link, only visible on focus
 * @accessibility: .skip-to-content
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

a.skip-to-content {
	overflow: hidden;
	position: absolute;
	top: -1000px;
	left: -1000px;
	width: 1px;
	height: 1px;
	text-align: left;
	&:active,
	&:focus,
	&:hover {
		overflow: visible;
		position: fixed;
		top: 0;
		left: 0;
		width: auto;
		height: auto;
		padding: 5px 10px;
		background-color: white;
		z-index: 9999999;
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Layout
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

html {
	// SEE: https://stackoverflow.com/questions/29551606/why-does-vw-include-the-scrollbar-as-part-of-the-viewport
	// AND: https://stackoverflow.com/questions/30489594/prevent-100vw-from-creating-horizontal-scroll
	overflow-x: hidden;
	overflow-y: scroll;
	background-color: white;
	font: $light 10px/1.15 $poppins;
	box-sizing: border-box;
	text-size-adjust: 100%;
	// -ms-overflow-style: -ms-autohiding-scrollbar;
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * NOTE: using `medium` font-sizes for old browser fallbacks
 * everything else is according to front end global style guide
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

body {
	margin: 0;
	background-color: white;
	color: $text-color;
	font-family: $poppins;
	font-size: 17px;
	font-size: 1.6rem; // scss-lint:disable DuplicateProperty
	line-height: lh(17, 27);
	line-height: lh(16, 26); // scss-lint:disable DuplicateProperty
	@media (--medium) {
		font-size: 1.7rem;
		line-height: lh(17, 27);
	}
	@media (--large-up) {
		font-size: 1.9rem;
		line-height: lh(19, 34);
	}
}

.no-page-scroll,
.menu-item-open {
	height: 100%;
	body {
		overflow: hidden;
		height: 100%;
	}
}

.layout {
	position: relative;
	max-width: $layout-max-width;
	min-height: 100vh;
	box-shadow: 0 0 0 3px $lighter-gray;
	@media (--medium-up) {
		display: flex;
	}
}

.column {
	display: flex;
	flex-direction: column;
	flex: 1 0 0;
	position: relative;
	width: 100%;
	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		pointer-events: none;
		z-index: 0;
	}
	&::before {
		height: 450px;
		background-color: #f2f3f2;
	}
	&::after {
		top: 450px;
		margin-top: -resolve(135 / 1200 * 100)vw;
		padding-top: resolve(135 / 1200 * 100)vw;
		background: url('svg/slant-f2f3f2.svg') no-repeat center white;
		background-size: 100% 100%;
		@media (--max) {
			margin-top: -214px;
			padding-top: 214px;
		}
	}
	.hero-page & {
		background-image: none;
		&::before,
		&::after {
			display: none;
		}
	}
	@media (--medium) {
		width: calc(100% - $nav-bar-width-medium);
	}
	@media (--large-up) {
		width: calc(100% - $nav-bar-width-large);
	}
}

.main {
	position: relative;
	max-width: 100vw;
	padding: 55px $side-margin-small 50px;
	outline: none;
	z-index: 1;
	@media (--medium) { max-width: calc(100vw - $nav-bar-width-medium); padding: resolve(55px + $sub-nav-bar-height) $side-margin-medium 55px; }
	@media (--large-up) { max-width: calc(100vw - $nav-bar-width-large); padding: resolve(80px + $sub-nav-bar-height) $side-margin-large 80px; }
	// this accounts for footer weirdness
	@media (--weird) { padding-bottom: 0; }
}
.block {
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
}
.fullwidth {
	position: relative;
	margin-right: -$side-margin-small;
	margin-left: -$side-margin-small;
	padding-right: $side-margin-small;
	padding-left: $side-margin-small;
	@media (--medium) {
		margin-right: -$side-margin-medium;
		margin-left: -$side-margin-medium;
		padding-right: $side-margin-medium;
		padding-left: $side-margin-medium;
	}
	@media (--large-up) {
		margin-right: -$side-margin-large;
		margin-left: -$side-margin-large;
		padding-right: $side-margin-large;
		padding-left: $side-margin-large;
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Hero layout
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.hero {
	margin-top: resolve(-50px - $sub-nav-bar-height);
	margin-bottom: 60px;
	background-color: black;
	color: white;
	.block {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-top: 20px;
		padding-bottom: 14px;
		// min-height: 320px;
	}
	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
	}
	.content {
		position: relative;
		z-index: 3;
		:last-child {
			margin-bottom: 0;
		}
	}
	h1 {
		text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
	}
	.intro {
		margin: 0;
	}
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0, transparent 50%);
		z-index: 2;
	}
	@media (--small) {
		display: block;
		height: auto;
		margin-top: -55px;
		padding: 0;
		.block {
			padding: 0;
		}
		img {
			position: static;
			width: 100%;
			height: 320px;
		}
		.content {
			padding: 0 15px;
			background-color: white;
		}
		h1 {
			position: absolute;
			right: 15px;
			left: 15px;
			margin-top: -15px;
			transform: translateY(-100%);
		}
		.intro {
			padding-top: 15px;
			color: $text-color;
		}
	}
	@media (--medium) {
		margin-top: resolve(-55px - $sub-nav-bar-height);
		.block {
			height: 320px;
			padding-bottom: 20px;
		}
	}
	@media (--large-up) {
		margin-top: resolve(-80px - $sub-nav-bar-height);
		.block {
			height: 600px;
			// min-height: 600px;
			padding-top: 50px;
			padding-bottom: 40px;
		}
	}
}


