/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for .testimonials pattern
 * @module: .testimonials
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

$slide-width: 260;
$slide-height: 350;
$brief-margin: 32;
$brief-width: resolve(260 - ($brief-margin * 2));

.testimonials {
	color: white;
	.track {
		display: flex;
	}
	.slide {
		flex: 1 0 auto;
		position: relative;
		background-color: black;
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
	.card {
		background: none no-repeat center transparent;
		// background-size: cover;
		background-size: auto 100%;
		transition: background-size 0.3s ease-in-out;
		&:hover {
			background-size: auto 105%;
		}
	}
	.outer {
		width: 100%;
		height: 0;
		padding: 0 0 percent($slide-height / $slide-width);
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0, transparent 75%);
	}
	.inner {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	.toggle {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		&::after {
			content: '';
			display: block;
			width: 22px;
			height: 22px;
			background: url('svg/expand-icon.svg') no-repeat center transparent;
			background-size: contain;
		}
	}
	.brief {
		margin: 0 percent($brief-margin / $slide-width) percent(26 / $slide-width);
		q {
			display: block;
			margin: 0 0 percent(19 / $brief-width);
			font: $medium 1.8rem/lh(18, 26) $poppins;
			text-align: center;
			&::before {
				content: '';
				display: block;
				width: percent(26 / $brief-width);
				height: 0;
				margin: 0 auto percent(17 / $brief-width);
				padding: 0 0 percent(26 / $brief-width);
				background: url('svg/quote-icon.svg') no-repeat center transparent;
				background-size: contain;
			}
		}
		figcaption {
			margin: 0;
			font: 1.2rem/lh(12, 17) $poppins;
			text-align: center;
			text-transform: uppercase;
		}
	}
	.testimonial-detail {
		display: none;
	}
}
.modal {
	.testimonial-detail {
		flex: 1 0 100%;
		max-width: resolve($layout-max-width - $nav-bar-width-large);
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center top;
			z-index: 1;
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0, transparent 90%);
			z-index: 2;
		}
		.content {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			position: relative;
			height: 100%;
			padding: percent(60 / 1200) percent(250 / 1200) percent(60 / 1200) percent(55 / 1200);
			z-index: 3;
			> * {
				max-width: 100%;
			}
		}
		h2 {
			margin: 0 0 percent(5 / 895);
			font: $semi-bold fw(65, 1280)/lh(65, 77) $poppins;
			text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
			transition: opacity 0.25s ease-in-out 0.25s;
			opacity: 0;
		}
		.text {
			margin: 0 0 percent(40 / 895);
			font: $medium fw(26, 1280)/lh(26, 40) $poppins;
			text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
			transition: opacity 0.25s ease-in-out 0.5s;
			opacity: 0;
			p:last-of-type {
				margin: 0;
			}
		}
		.btn {
			margin: 0;
			transition: opacity 0.25s ease-in-out 0.75s;
			opacity: 0;
		}
	}
	&.open {
		.testimonial-detail {
			h2,
			.text,
			.btn {
				opacity: 1;
			}
		}
	}
}

@media (--small) {
	.testimonials {
		overflow: hidden;
		margin-right: -15px;
		margin-left: -15px;
		padding-right: 15px;
		padding-left: 15px;
		.slide {
			flex: 1 0 calc(25% - 15px);
		}
	}
	.modal {
		.testimonial-detail {
			h2 {
				font-size: 4rem;
			}
			.text {
				font-size: 1.6rem;
			}
		}
	}
}

@media (--medium) {
	.testimonials {
		.track {
			flex-wrap: wrap;
		}
		.slide {
			flex-basis: 40%;
			&:nth-child(1) {
				margin: 0 10px 10px 0;
			}
			&:nth-child(2) {
				margin: 0 0 10px;
			}
		}
	}
	.modal {
		.testimonial-detail {
			h2 {
				font-size: 5.2rem;
			}
			.text {
				font-size: 2.1rem;
			}
		}
	}
}
@media (--large) {
	.testimonials {
		margin-bottom: 120px !important; // scss-lint:disable ImportantRule
	}
	.modal {
		.testimonial-detail {
			.btn {
				padding: 22px 50px;
				font: $semi-bold 2.2rem/1.4 $poppins;
			}
		}
	}
}
@media (--max) {
	.testimonials {
		.testimonial-detail {
			h2 {
				font-size: 9.6rem;
			}
			.text {
				font-size: 3.7rem;
			}
		}
	}
}
