/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for video pattern
 * @module: .video-media
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.video-block {
	.caption {
		padding: 15px;
		background-color: $red;
		color: white;
		font: 1.4rem/1.2 $poppins;
	}
}
.video-media {
	position: relative;
	img {
		width: 100%;
	}
	button {
		position: absolute;
		top: 50%;
		left: 50%;
		width: percent(100 / 1080);
		padding: 0 0 percent(100 / 1080);
		background: url('svg/video-play-button.svg') no-repeat center transparent;
		background-size: contain;
		border: 0;
		transform: translate(-50%, -50%);
		cursor: pointer;
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url('svg/video-play-button-hover.svg') no-repeat center transparent;
			background-size: contain;
			transition: opacity 0.3s ease-in-out;
			opacity: 0;
		}
		&:hover::after {
			opacity: 1;
		}
	}
	.video-modal-content {
		display: none;
	}
}
.video {
	margin: 0 0 50px;
	.video-frame {
		position: relative;
		height: 0;
		// aspect ratio = 16:9 = 100 * 9/16
		padding-bottom: 56.25%;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Videos in modal
 * @module: .video-modal
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.video-modal {
	background-color: rgba(0, 0, 0, 0.7);
	.video-modal-content {
		display: flex;
		flex-direction: column;
		flex: 1 0 100%;
		&::before {
			content: '';
			display: block;
			flex: 0 0 60px;
		}
	}
	.video {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1 0 0;
		margin: 0;
	}
}
