/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for accordion pattern
 * @module: .accordion
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.accordion {
	margin: 0 0 50px;
	.controls {
		display: flex;
		margin: 0 0 15px;
		button {
			display: flex;
			align-items: center;
			margin-right: 26px;
			color: $red;
			font: $semi-bold 1.4rem/lh(14, 20) $poppins;
			&::before {
				content: '';
				display: block;
				width: 12px;
				height: 8px;
				margin: 0 8px 0 0;
				background: url('svg/accordion-arrow-down.svg') no-repeat center transparent;
				background-size: contain;
			}
			&.collapse-all::before {
				background-image: url('svg/accordion-arrow-up.svg');
			}
		}
	}
	.expand-all {
		cursor: pointer;
		&[aria-expanded="true"] {
			color: #555;
			cursor: default;
		}
	}
	.collapse-all {
		cursor: pointer;
		&[aria-expanded="false"] {
			color: #555;
			cursor: default;
		}
	}
	.sections {
		margin: 0;
		padding: 0;
		background-color: $lighter-gray;
		border: 1px solid #979797;
		border-bottom: 0;
		list-style: none;
		@media (--small) {
			margin: 0 -$side-margin-small;
			border-right: 0;
			border-left: 0;
		}
	}
	.section {
		border-bottom: 1px solid #979797;
	}
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0 15px;
		font: $semi-bold 1.6rem/lh(16, 50) $poppins;
		&:hover {
			background-color: #e4e6e4;
		}
		&::after {
			content: '';
			display: block;
			flex: 0 0 16px;
			height: 16px;
			margin: 0 0 0 12px;
			background: url('svg/accordion-plus.svg') no-repeat center transparent;
			background-size: contain;
		}
		&[aria-expanded="true"] {
			margin: 0;
			&::after {
				background-image: url('svg/accordion-minus.svg');
			}
			+ .content {
				display: block;
			}
		}
	}
	.content {
		display: none;
		padding: 0 15px;
		font: 1.6rem/lh(16, 26) $poppins;
	}
	// p {}
	.back-to-top {
		display: flex;
		align-items: center;
		margin: 0 0 15px;
		color: $red;
		font: $semi-bold 1.4rem/lh(14, 20) $poppins;
		&::before {
			content: '';
			display: block;
			width: 12px;
			height: 8px;
			margin: 0 8px 0 0;
			background: url('svg/accordion-arrow-up.svg') no-repeat center transparent;
			background-size: contain;
		}
	}

}
