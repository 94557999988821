/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for media blocks
 * @module: `.media-block`
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.media-block {
	width: 100%;
	font: 1.6rem/lh(16, 28) $poppins;
	.img {
		display: block;
		position: relative;
		width: 100%;
		margin: 0 0 18px;
	}
	img {
		width: 100%;
	}
	.title {
		margin: 0 0 12px;
		font: $semi-bold 1.8rem/lh(18, 28) $poppins;
	}
	.subtitle {
		margin: 0;
		font: $semi-bold 1.6rem/lh(16, 28) $poppins;
		text-transform: uppercase;
	}
	.content {
		p:last-of-type {
			margin-bottom: 0;
		}
	}
}

@media (--small) {
	.media-block {
		margin-bottom: 40px;
	}
}
@media (--medium-up) {
	.media-block {
		display: flex;
		align-items: flex-start;
		margin: 0 0 40px;
		.img {
			margin: 0 20px 0 0;
		}
		.img,
		.content {
			flex: 1 0 50%;
		}
		.title {
			font: $semi-bold 2rem/lh(20, 28) $poppins;
		}
	}
}
@media (--large-up) {
	.degrees-list-block .media-block {
		.title {
			font-size: 2.6rem;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: `.column-2` and `.column-3`
 * NOTE special exception for `.column-2` blocks
 * at medium responsive widths and `.news-block`,
 * `.column-2`, and `.column-3` blocks at large
 * and up responsive widths
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

@media (--medium) {
	.media-blocks.column-2 {
		.list {
			display: flex;
		}
		.media-block {
			display: block;
			flex: 1 0 0;
			margin-bottom: 0;
			&:not(:first-child) {
				margin-left: 10px;
			}
		}
		.img {
			margin: 0 0 18px;
		}
	}
}

@media (--large-up) {
	.media-blocks.column-2,
	.media-blocks.column-3 {
		.list {
			display: flex;
		}
		.media-block {
			display: block;
			flex: 1 0 0;
			margin-bottom: 0;
			&:not(:first-child) {
				margin-left: 40px;
			}
		}
		.img {
			margin: 0 0 25px;
		}
	}
}
