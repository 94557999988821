/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for instagram block pattern
 * @module: .instagram-block
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.instagram-block {
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: $lighter-gray;
	h2 {
		display: flex;
		align-items: center;
		margin: 0 0 25px;
		font: $semi-bold 2.2rem/lh(22, 31) $poppins;
		&::before {
			content: '';
			display: block;
			width: 50px;
			height: 50px;
			margin: 0 10px 0 0;
			background: url('svg/icon-social-instagram-teal.svg') no-repeat center transparent;
			background-size: contain;
		}
		a {
			color: $text-color;
		}
	}
	.items {
		display: flex;
		flex-wrap: wrap;
	}
	.item {
		flex: 1 0 100%;
		transition: opacity 0.3s ease-in-out;
		opacity: 0;
		&.loaded {
			opacity: 1;
		}
	}
	.bg {
		overflow: hidden;
		position: relative;
		padding: 0 0 percent(290 / 290);
		background: none no-repeat center $text-color;
		background-size: cover;
	}
	img {
		position: absolute;
		width: 100%;
		opacity: 0;
		pointer-events: none;
	}
}
@media (--small) {
	.instagram-block {
		.item {
			&:first-child {
				margin-bottom: 10px;
			}
			&:nth-child(n+3) {
				display: none;
			}
		}
	}
}
@media (--medium) {
	.instagram-block {
		.item {
			flex: 1 0 40%;
			&:nth-child(odd) {
				margin-right: 10px;
			}
			&:nth-child(-n+2) {
				margin-bottom: 10px;
				background-color: yellow;
			}
		}
	}
}
@media (--large-up) {
	.instagram-block {
		padding-top: 60px;
		padding-bottom: 60px;
		.item {
			flex: 1 0 20%;
			&:not(:last-child) {
				margin: 0 10px 0 0;
			}
		}
	}
}
