/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Displays an alphabetical list of all Degree content
 * objects that match the tag the author has selected
 * NOTE: this pattern relies on `.media-blocks` and
 * `.media-block` patterns (via class inheritance).
 * @module: .degrees
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.degrees {
	.option {
		display: flex;
		align-items: center;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 40px;
		padding: 12px 15px;
		background-color: $red;
		color: white;
		font: $semi-bold 1.2rem/1 $poppins;
		-webkit-font-smoothing: antialiased;
		text-transform: uppercase;
		&.online::before {
			content: '';
			display: block;
			width: 19px;
			height: 15px;
			margin: 0 12px 0 0;
			background: url('svg/icon-online.svg') no-repeat center transparent;
			background-size: contain;
		}
	}
	.sub-list {
		margin: 0;
		padding: 0 0 0 20px;
		list-style: none;
		li {
			&::before {
				content: '•';
				display: inline-block;
				width: 20px;
				margin-left: -20px;
				color: $red;
			}
		}
		a {
			color: $red;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Generic block 2 column + cta
 * @module: .column-2-cta
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.column-2-cta {
	.items {
		display: flex;
	}
	.item {
		flex: 1 0 50%;
		&:first-child {
			margin-right: 25px;
		}
	}
	.title {
		font: $semi-bold 1.6rem/lh(16, 23) $poppins;
	}
	.caption {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: $red;
		color: white;
		text-align: center;
		p {
			-webkit-font-smoothing: antialiased;
		}
	}
	.wrap {
		padding: 20px;
	}
	.btn {
		margin: 0;
	}
}
@media (--small) {
	.column-2-cta {
		.items {
			display: block;
		}
	}
}
@media (--medium) {
	.column-2-cta {
		h2 {
			margin: 0 0 35px;
		}
		.row:last-of-type p:last-of-type {
			margin: 0;
		}
		.title {
			font: $semi-bold 1.7rem/lh(17, 27) $poppins;
		}
	}
}
@media (--large-up) {
	.column-2-cta {
		h2 {
			margin: 0 0 35px;
		}
		.item {
			&:first-child {
				margin-right: 45px;
			}
		}
		.row:last-of-type p:last-of-type {
			margin: 0;
		}
		.title {
			font: $semi-bold 2.6rem/lh(26, 36) $poppins;
		}
		.caption {
			padding: 20px;
		}
	}
}
