/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * News List
 * Page displays all News objects in reverse
 * chronological order. 10 objects are displayed
 * per page.
 * @module: .news-list
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.news-list-results {
	margin: 15px 0 95px;
	.results {
		margin: 0 0 20px;
		font: 1.8rem/lh(18, 28) $poppins;
	}
	.btn {
		display: inline-block;
		margin: 0;
		cursor: pointer;
	}
}
.news-list {
	margin-top: 50px;
	.date {
		margin: 0 0 10px;
		font: $semi-bold 1.2rem/lh(12, 17) $poppins;
		text-transform: uppercase;
	}
	.title {
		font-size: 1.8rem;
	}
	.body {
		display: none;
	}
	@media (--medium) {
		.title { font-size: 2rem; }
	}
	@media (--medium-up) {
		.media-block .img { flex: 1 0 33%; }
		.media-block .content { flex: 1 0 67%; }
	}
	@media (--large-up) {
		.media-block { margin-bottom: 60px; }
		.title { font-size: 2.6rem; }
		.body { display: block; }
	}
}
@media (--medium) {
	.news-list-results {
		display: flex;
		align-items: center;
		margin: 30px 0 80px;
		.results {
			margin: 0 50px 0 0;
			font: 2rem/lh(20, 30) $poppins;
		}
	}
}
@media (--large-up) {
	.news-list-results {
		display: flex;
		align-items: center;
		margin: 35px 0 150px;
		.results {
			margin: 0 65px 0 0;
			font: 2.6rem/lh(26, 40) $poppins;
		}
	}
	.news-list {
		.date {
			margin: 0 0 16px;
			font: $semi-bold 1.6rem/lh(16, 23) $poppins;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: .list-navigation
 * Pagination navigation for lists
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.list-navigation {
	display: flex;
	justify-content: center;
	font: $semi-bold 1.6rem/1 $poppins;
	cursor: default;
	ul {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 56px;
		padding: 0 25px;
		border: 3px solid #cacaca;
		border-radius: 50px;
	}
	button {
		cursor: pointer;
	}
	li {
		min-width: 22px;
		height: 22px;
		border-radius: 30px;
		-webkit-font-smoothing: antialiased;
		line-height: 22px;
		text-align: center;
		button {
			display: block;
			width: 100%;
			padding: 0;
			background-color: transparent;
			border: 0;
			color: $text-color;
		}
	}
	.prev,
	.next {
		button {
			&::after {
				content: '';
				display: block;
				width: 10px;
				height: 22px;
				background: url('svg/list-nav-prev-on.svg') no-repeat center transparent;
				background-size: contain;
			}
		}
	}
	.prev {
		button {
			margin-right: 15px;
		}
		&.inactive button {
			cursor: default;
			pointer-events: none;
			&::after {
				background-image: url('svg/list-nav-prev.svg');
			}
		}
	}
	.next {
		button {
			margin-left: 15px;
			&::after {
				background-image: url('svg/list-nav-next-on.svg');
			}
		}
		&.inactive button {
			cursor: default;
			pointer-events: none;
			&::after {
				background-image: url('svg/list-nav-next.svg');
			}
		}
	}
	.on {
		background-color: $red;
		color: white;
		span {
			padding: 0 5px;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: .news-events-detail
 * Used for News and Events Detail page
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.news-events-detail {
	margin-top: 20px;
	.media {
		width: 100%;
		margin: 0 0 30px;
	}
	img {
		width: 100%;
	}
	.caption {
		padding: 17px 20px;
		background-color: $red;
		color: white;
		font: 1.5rem/lh(15, 21) $poppins;
		-webkit-font-smoothing: antialiased;
	}
	.date {
		margin: 0 0 12px;
		font: $semi-bold 1.2rem/1 $poppins;
		text-transform: uppercase;
	}
}
@media (--small) {
	.news-events-detail {
		.caption {
			display: none;
		}
	}
}
@media (--large-up) {
	.news-events-detail {
		.media {
			margin: 0 0 55px;
		}
		.caption {
			font: 1.6rem/lh(16, 23) $poppins;
		}
		.date {
			font: $semi-bold 1.6rem/lh(16, 23) $poppins;
		}
	}
}
