/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for call to action patterns
 * @module: .cta
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.cta {
	overflow: hidden;
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: black;
	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
	}
	.content {
		position: relative;
		z-index: 3;
	}
	h2 {
		margin: 0 0 20px;
		font-size: 3rem;
		line-height: lh(30, 40);
	}
	&.has-bg {
		background-color: $lighter-gray;
		.block {
			position: relative;
			z-index: 2;
		}
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			pointer-events: none;
			z-index: 1;
		}
		&::before {
			height: calc(50% - resolve(135 / 1200 * 100 / 2)vw);
			background-color: #e5e6e5;
			@media (--max) {
				height: 19px;
			}
		}
		&::after {
			top: calc(50% - resolve(135 / 1200 * 100 / 2)vw - 1px);
			padding-top: resolve(135 / 1200 * 100)vw;
			background: url('svg/slant-e5e6e5.svg') no-repeat center transparent;
			background-size: 100% 100%;
			@media (--max) {
				top: 18px;
				padding-top: 214px;
			}
		}
	}
	&.has-image {
		background: none black;
		h2 {
			color: white;
			font-size: 3.7rem;
			line-height: lh(37, 48);
			text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.15);
			z-index: 2;
		}
	}
	.buttons {
		margin: 0;
	}
}


@media (--small) {
	.cta {
		.buttons {
			.btn:not(:first-child) {
				margin: 0 0 10px;
			}
		}
	}
}

@media (--medium-down) {
	.cta {
		h2 {
			text-align: center;
		}
		.wrap {
			display: flex;
			justify-content: center;
		}
		.buttons {
			flex-direction: column;
			align-items: center;
		}
		.btn {
			width: 100%;
			max-width: 90vw;
		}
	}
}

@media (--medium) {
	.cta {
		h2 {
			font-size: 4rem;
			line-height: lh(40, 50);
		}
		&.has-image {
			h2 {
				font-size: 5rem;
				line-height: lh(50, 60);
			}
		}
	}
}

@media (--large-up) {
	.cta {
		h2 {
			margin: 0 0 25px;
			font-size: 5rem;
			line-height: lh(50, 60);
		}
		&.has-image {
			h2 {
				margin: 0 0 25px;
				font-size: 6.5rem;
				line-height: lh(65, 75);
			}
		}
		.buttons {
			.btn {
				margin: 0 0 0 10px;
			}
		}
	}
}
