/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: .stories-ctas
 * Statistics display object. Selection of statistics
 * highlighting what SBCC offers students academically.
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

$story-width-outer: 290; // 320 - 15 - 15
$story-width-inner: 246; // 290 - 22 - 22

.stories-ctas {
	.item {
		margin: 0 0 40px;
	}
	p {
		margin: 0 0 18px;
		text-align: center;
	}
	.wrap {
		display: flex;
		justify-content: center;
	}
	.buttons {
		flex-direction: column;
		align-items: center;
		margin: 0 0 35px;
	}
	.btn {
		width: 100%;
		max-width: 90vw;
	}
	.story {
		width: 100%;
		margin: 0;
		background: none no-repeat center black;
		background-size: cover;
		color: white;
	}
	.mask {
		width: 100%;
		margin: 0;
		padding: 0 0 percent(345 / $story-width-outer);
		&::after {
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0, transparent 80%);
		}
	}
	.mask-cage {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0 percent(22 / $story-width-outer) percent(25 / $story-width-outer);
		> * {
			width: 90%;
		}
	}
	q {
		display: block;
		margin: 0 0 percent(14 / $story-width-inner);
		// font: $medium fw(18, 320)/lh(18, 26) $poppins;
		font: $medium 1.8rem/lh(18, 26) $poppins;
		-webkit-font-smoothing: antialiased;
		text-align: center;
		&::before {
			content: '';
			display: block;
			width: 30px;
			height: 27px;
			margin: 0 auto 18px;
			background: url('svg/quote-icon.svg') no-repeat center transparent;
			background-size: contain;
		}
	}
	figcaption {
		margin: 0;
		// font: fw(12, 320)/lh(12, 17) $poppins;
		font: 1.2rem/lh(12, 17) $poppins;
		-webkit-font-smoothing: antialiased;
		text-align: center;
		text-transform: uppercase;
	}
}
@media (--small) {
	.stories-ctas {
		.text {
			text-align: left;
		}
	}
}
@media (--medium-up) {
	.stories-ctas {
		.item {
			display: flex;
			align-items: flex-start;
			margin: 0 0 75px;
			&:last-child {
				margin: 0;
			}
			&.left {
				.texts {
					order: 2;
				}
				.story {
					order: 1;
					margin: 0 30px 0 0;
				}
			}
		}
		.texts {
			flex: 1 0 0;
		}
		p {
			text-align: left;
		}
		.wrap {
			justify-content: flex-start;
		}
		.buttons {
			margin: 0;
		}
		.btn {
			&:last-child {
				margin: 0;
			}
		}
		.story {
			flex: 1 0 0;
			margin: 0 0 0 30px;
		}
	}
}
@media (--large-up) {
	.stories-ctas {
		.btn {
			padding: 22px 50px;
			font: $semi-bold 2.2rem/1.4 $poppins;
		}
		.item {
			margin: 0 0 90px;
		}
		.text {
			margin-bottom: 40px;
		}
		q {
			font: $medium 2rem/lh(18, 26) $poppins;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: .stats-block
 * Statistics display object. Selection of statistics
 * highlighting what SBCC offers students academically.
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.stats-block {
	background-color: $lighter-gray;
	text-align: center;
	.mask-cage {
		padding: 35px 0 0;
	}
	.item {
		display: block;
		margin-bottom: 50px;
		color: $text-color;
		text-decoration: none;
		&:hover {
			&::before {
				transform: translateY(-10px);
			}
		}
		&::before {
			content: '';
			display: block;
			height: 133px;
			margin: 0 auto 8px;
			background: none no-repeat top center transparent;
			background-size: contain;
			transition: transform 0.3s ease-in-out;
		}
		&.student-faculty::before {
			width: 109px;
			background-image: url('svg/icon-student-faculty.svg');
		}
		&.instructor::before {
			width: 118px;
			background-image: url('svg/icon-instructor.svg');
		}
		&.pencil-paper::before {
			width: 111px;
			background-image: url('svg/icon-pencil-paper.svg');
		}
		&.diploma::before {
			width: 96px;
			background-image: url('svg/icon-diploma.svg');
		}
	}
	h2 {
		margin: 0;
		font: 1.7rem/lh(17, 27) $poppins;
	}
	strong {
		display: block;
		margin: 0 0 10px;
		font: $semi-bold 6.5rem/1 $poppins;
	}
}
@media (--medium) {
	.stats-block {
		.items {
			display: flex;
			flex-wrap: wrap;
		}
		.item {
			flex: 1 0 40%;
			margin: 0 5% 50px;
		}
		strong {
			font: $semi-bold 7.5rem/1 $poppins;
		}
	}
}
@media (--large-up) {
	.stats-block {
		.items {
			display: flex;
			justify-content: space-between;
		}
		.item {
			max-width: 20%;
			margin: 0;
		}
		strong {
			font: $semi-bold 7.5rem/1 $poppins;
		}
	}
}
