/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for twitter pattern
 * @module: .twitter-block
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.twitter-block {
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: $lighter-gray;
	h2 {
		display: flex;
		align-items: center;
		margin: 0 0 25px;
		font: $semi-bold 2.2rem/lh(22, 31) $poppins;
		&::before {
			content: '';
			display: block;
			width: 50px;
			height: 50px;
			margin: 0 10px 0 0;
			background: url('svg/icon-social-twitter-teal.svg') no-repeat center transparent;
			background-size: contain;
		}
		a {
			color: $text-color;
		}
	}
	.item {
		&:not(:last-child) {
			margin: 0 0 25px;
			padding: 0 0 25px;
			border-bottom: 1px solid #979797;
		}
	}
	a {
		color: #00767a;
	}
}

@media (--large-up) {
	.twitter-block {
		padding-top: 60px;
		padding-bottom: 60px;
		.items {
			display: flex;
			.item {
				flex: 1 0 0;
				margin: 0;
				padding: 0;
				border: 0;
				&:not(:first-child) {
					margin: 0 0 0 60px;
				}
			}
		}
	}
}
