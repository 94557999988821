/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Basic exceptions for homepage
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

// 65px at large, 50px at medium, and 36px at small.
.page-home {
	h2,
	.h2 {
		font-size: 3.6rem;
	}
	@media (--medium) {
		h2,
		.h2 {
			font-size: 5rem;
		}
	}
	@media (--large-up) {
		h2,
		.h2 {
			font-size: 6.5rem;
		}
	}
	.column {
		background-color: $lighter-gray;
		background-image: none;
	}
	.main {
		padding-bottom: 0;
		background-color: white;
		z-index: 2;
	}
	.instagram-block,
	.twitter-block {
		h2 {
			font: $semi-bold 2.2rem/lh(22, 31) $poppins;
		}
	}
	.footer {
		position: relative;
		z-index: 2;
		.top {
			overflow: hidden;
			padding-top: 40px;
			background-color: white;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
* Special home page call to actions
* @module: .home-cta-special
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.home-cta-special {
	background-color: $lighter-gray;
	text-align: center;
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 150px;
		background-image: linear-gradient(to bottom, rgba(242, 243, 242, 1) 0, rgba(242, 243, 242, 0) 100%);
		z-index: 2;
	}
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		// object-position: center top;
		z-index: 1;
	}
	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		width: 100%;
		height: 540px;
		padding: 0 $side-margin-small;
		z-index: 3;
		@media (--small) {
		}
		@media (--medium) {
			height: 600px;
		}
		@media (--large-up) {
			height: 780px;
		}
		@media (--huge) {
			height: 900px;
		}
		@media (width >= 1750px) {
			height: 1000px;
		}
	}
	.content-wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 85%;
	}
	.title {
		@media (width < 425px) {
			width: 200px;
			margin: 0 auto;
		}
	}
	.wrap {
		display: flex;
		justify-content: center;
	}
	.buttons {
		flex-direction: column;
		align-items: center;
		margin: 0;
	}
	.btn {
		width: 100%;
		max-width: 90vw;
		margin: 0 0 10px;
		font: $semi-bold 1.8rem/lh(18, 24) $poppins;
		&:last-child {
			margin: 0;
		}
	}
}
// @media (--small) {
// 	.home-cta-special {
// 	}
// }
@media (--medium) {
	.home-cta-special {
		.btn {
			font: $semi-bold 2rem/lh(20, 28) $poppins;
		}
	}
}
@media (--large-up) {
	.home-cta-special {
		text-align: left;
		.texts {
			flex: 1 0 0;
		}
		.wrap {
			justify-content: flex-end;
			flex: 1 0 auto;
		}
		.buttons {
			justify-content: flex-end;
			margin: 0 0 25px;
		}
		.btn {
			margin: 0 0 24px;
			padding: 22px 50px;
			font: $semi-bold 2.2rem/1.4 $poppins;
			text-align: center;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Other home page call to actions
 * @module: .home-cta
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.home-cta {
	position: relative;
	text-align: center;
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		right: 0;
		bottom: 50%;
		left: 0;
		width: 100%;
		padding-bottom: percent(135 / 1200);
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		z-index: 2;
	}
	html & {
		// &::after {
		// 	width: calc(100% + 500px);
		// 	padding-top: resolve(190 / 1200 * 100)vw;
		// 	background-image: linear-gradient(6.5deg, white 49.95%, transparent 50%);
		// 	transform: translateX(0);
		// 	transition: transform 0.6s ease-in-out;
		// }
		&::after {
			padding-top: 0;
			transition: padding-top 0.6s ease-in-out;
		}
	}
	html.home-slant-1-animation &::after {
		padding-top: resolve(135 / 1200 * 100)vw;
	}
	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
	}
	.content {
		position: relative;
		z-index: 3;
	}
	.title {
		margin: 0 0 40px;
	}
	.texts {
		margin: 0 0 260px;
	}
	.description {
		font: 1.8rem/lh(18, 29) $poppins;
	}
	.wrap {
		display: flex;
		justify-content: center;
	}
	.buttons {
		flex-direction: column;
		align-items: center;
		margin: 0 0 50px;
	}
	.btn {
		width: 100%;
		max-width: 90vw;
		margin: 0 0 10px;
		font: $semi-bold 1.8rem/lh(18, 24) $poppins;
	}
}
@media (--medium) {
	.home-cta {
		.texts {
			margin: 0 0 300px;
		}
		.description {
			font: 2rem/lh(20, 32) $poppins;
		}
		.btn {
			font: $semi-bold 2rem/lh(20, 28) $poppins;
		}
	}
}
@media (--large-up) {
	.home-cta {
		text-align: left;
		.content {
			display: flex;
			justify-content: space-between;
		}
		.title {
			margin: 0 0 50px;
		}
		.texts {
			flex: 0 0 50%;
			margin: 0 0 485px;
		}
		.description {
			font: 2.2rem/lh(22, 37) $poppins;
		}
		.wrap {
			justify-content: flex-end;
			flex: 0 0 50%;
		}
		.buttons {
			margin: 0 0 25px;
		}
		.btn {
			margin: 0 0 24px;
			padding: 22px 50px;
			font: $semi-bold 2.2rem/1.4 $poppins;
			text-align: center;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: `.home-stats` on home page
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.home-stats {
	padding: 0 40px;
	text-align: center;
	h2 {
		margin: 0 0 45px;
	}
	.item {
		display: block;
		margin-bottom: 50px;
		color: $text-color;
		text-decoration: none;
		&:hover {
			// &::before {
			// 	transform: translateY(-10px);
			// }
			&::after {
				transform: translateX(10px);
				filter: brightness(0.7);
			}
		}
		&::before {
			content: '';
			display: block;
			height: 105px;
			margin: 0 auto;
			background: none no-repeat top center transparent;
			background-size: contain;
			// transition: transform 0.3s ease-in-out;
		}
		&::after {
			content: '';
			display: block;
			width: 28px;
			height: 21px;
			margin: 0 auto;
			background: url('svg/arrow-red.svg') no-repeat center transparent;
			background-size: contain;
			transition: transform 0.3s ease-in-out;
		}
		&.scholarship::before {
			width: 61px;
			background-image: url('svg/icon-scholarship.svg');
		}
		&.financial-aid::before {
			width: 80px;
			background-image: url('svg/icon-financial-aid.svg');
		}
		&.mortar-board::before {
			width: 104px;
			background-image: url('svg/icon-mortar-board.svg');
		}
		&.piggy-bank::before {
			width: 90px;
			background-image: url('svg/icon-piggy-bank.svg');
		}
	}
	h3 {
		margin: 0 0 12px;
		font: 1.7rem/lh(17, 27) $poppins;
	}
	strong {
		display: block;
		font: $semi-bold 6.5rem/1 $poppins;
	}
	.subtitle {
		display: block;
		font: $semi-bold 2.8rem/lh(28, 37) $poppins;
	}
}
@media (--medium-up) {
	.home-stats {
		.item {
			transform: translateY(30px);
			opacity: 0;
			&.scholarship { transition: opacity 0.4s ease-out, transform 0.4s ease-out; }
			&.financial-aid { transition: opacity 0.4s ease-out 0.3s, transform 0.4s ease-out 0.3s; }
			&.mortar-board { transition: opacity 0.4s ease-out 0.6s, transform 0.4s ease-out 0.6s; }
			&.piggy-bank { transition: opacity 0.4s ease-out 0.9s, transform 0.4s ease-out 0.9s; }
			.home-stats-animation & {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}
@media (--medium) {
	.home-stats {
		h2 {
			margin: 0 0 50px;
			padding: 0 25%;
		}
		.items {
			display: flex;
			// justify-content: space-between;
			flex-wrap: wrap;
		}
		.item {
			flex: 1 0 30%;
			margin: 0 10% 50px;
		}
	}
}
@media (--large-up) {
	.home-stats {
		padding: 0;
		h2 {
			margin: 0 0 60px;
			text-align: left;
		}
		.items {
			display: flex;
			justify-content: space-between;
		}
		.item {
			max-width: 20%;
			margin: 0;
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: .home-social-blocks
 * special to home page
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.home-social-blocks {
	position: relative;
	padding-top: resolve(135 / 1200 * 100)vw;
	background-color: $lighter-gray;
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		right: 0;
		left: 0;
		padding-top: 0;
		background: url('svg/slant-white.svg') no-repeat center transparent;
		background-size: 100% 100%;
		transform: rotate(180deg);
		transition: padding-top 0.6s ease-in-out;
		z-index: 1;
		.home-slant-2-animation & {
			padding-top: resolve(135 / 1200 * 100)vw;
		}
	}
	.wrap {
		position: relative;
		padding-bottom: 10px;
		background-color: $lighter-gray;
		z-index: 2;
	}
}
@media (--medium-up) {
	.home-social-blocks {
		.home-social-block {
			transform: translateY(30px);
			opacity: 0;
			&.instagram-block { transition: opacity 0.4s ease-out, transform 0.4s ease-out; }
			&.twitter-block { transition: opacity 0.4s ease-out 0.3s, transform 0.4s ease-out 0.3s; }
			.home-social-animation & {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}
@media (--medium) {
	.home-social-blocks {
		.wrap {
			padding-bottom: 20px;
		}
	}
}
@media (--large-up) {
	.home-social-blocks {
		.wrap {
			display: flex;
			padding-bottom: 20px;
		}
		.instagram-block,
		.twitter-block {
			flex: 0 0 calc(50% - 60px);
			width: calc(50% - 60px);
		}
		.instagram-block {
			margin-right: 60px;
			.item {
				flex: 1 0 40%;
				margin: 0;
				&:nth-child(odd) {
					margin-right: 10px;
				}
				&:nth-child(-n+2) {
					margin-bottom: 10px;
				}
			}
		}
		.twitter-block {
			.items {
				display: block;
				.item {
					&:not(:last-child) {
						margin: 0 0 25px;
						padding: 0 0 25px;
						border: 0;
						border-bottom: 1px solid #979797;
					}
					&:not(:first-child) {
						margin: 0 0 25px;
					}
				}
			}
		}
	}
}
