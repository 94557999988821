/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * sub navigation
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.nav-sub-bar {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: $sub-nav-bar-height;
	z-index: 2;
}

.nav-sub {
	position: relative;
	color: white;
	button {
		position: relative;
		width: 100%;
		height: $sub-nav-bar-height;
		margin: 0;
		padding: 0;
		background-color: $text-color;
		border: 0;
		border-radius: 0;
		font-size: 1.6rem;
		line-height: $sub-nav-bar-height;
		transition: background-color 0.3s ease-in-out;
		cursor: pointer;
		z-index: 2;
		&:hover {
			background-color: #363535;
		}
		span {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 15px;
			&::after {
				content: '';
				display: block;
				width: 17px;
				height: 17px;
				margin: 0 0 0 12px;
				background: url('svg/plus-icon.svg') no-repeat center transparent;
				background-size: contain;
			}
		}
		&[aria-expanded="true"] + .dropdown {
			transform: translateY(0);
			opacity: 1;
			pointer-events: all;
		}
	}
	.dropdown {
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		top: $sub-nav-bar-height;
		right: 0;
		left: 0;
		max-height: calc(100vh - resolve($sub-nav-bar-height + $nav-bar-height-small));
		background-color: $text-color;
		font: $semi-bold 1.5rem/lh(15, 21) $poppins;
		transform: translateY(-100px);
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		opacity: 0;
		pointer-events: none;
		z-index: 1;
		> ul {
			max-width: 425px;
			padding: 20px 15px;
		}
		li {
			li {
				padding-left: 10px;
			}
		}
		a {
			display: inline-block;
			margin: 0 0 12px 18px;
			color: white;
			text-indent: -18px;
		}
	}
}

@media (--small) {
	.nav-sub-bar {
		position: relative;
	}
}
@media (--medium-up) {
	.nav-sub-bar {
		display: flex;
		position: fixed;
		top: 0;
		right: 0;
		left: $nav-bar-width-medium;
	}
	.nav-sub {
		min-width: 160px;
		margin-left: 30px;
		.dropdown {
			right: auto;
			width: 425px;
			max-height: calc(100vh - $sub-nav-bar-height);
		}
	}
}

@media (--large-up) {
	.nav-sub-bar {
		left: $nav-bar-width-large;
		background-color: rgba(34, 34, 34, 0.7);
	}
	.nav-sub {
		margin-left: 60px;
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * breadcrumbs
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.breadcrumbs {
	display: none;
	color: white;
	font: $semi-bold 1.4rem/$sub-nav-bar-height $poppins;
	@media (--large-up) {
		display: block;
	}
	ul {
		display: flex;
		margin: 0 0 0 18px;
	}
	li {
		display: flex;
		align-items: center;
		margin: 0 0 0 12px;
		// padding: 0 0 0 12px;
		&:not(:first-child)::before {
			content: '';
			display: block;
			width: 6px;
			height: 10px;
			margin: 0 12px 0 0;
			background: url('svg/breadcrumb-arrow.svg') no-repeat center transparent;
		}
	}
	li:last-child {
		font-weight: 400;
	}
	a {
		color: white;
		text-decoration: none;
	}
	span {
		&:hover {
			border-bottom: 2px solid white;
		}
	}
}
