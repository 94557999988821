/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * GENERIC page `.mb` elements to give
 * page modules/blocks/elements page margin flow
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.mb {
	margin-bottom: 70px;
	@media (--medium-up) {
		margin-bottom: 80px;
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Various masks that show up on block tops/bottoms
 * `.mask` + `.mask-slant-*` or `.mask-fade-*` etc.
 * `.mask-img` for background images
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.mask {
	position: relative;
	margin-bottom: 15px;
	@media (--medium-up) {
		margin-bottom: 40px;
	}
}
.mask-img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	pointer-events: none;
	z-index: 1;
}
.mask-cage {
	position: relative;
	z-index: 3;
}
[class*="mask-top"]::before,
[class*="mask-bottom"]::after {
	content: '';
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	pointer-events: none;
	z-index: 2;
}
[class*="mask-top"]::before { top: -1px; }
[class*="mask-bottom"]::after { bottom: -1px; }
.mask-top-slant {
	padding-top: resolve(135 / 1200 * 100)vw;
	&::before {
		padding-top: resolve(135 / 1200 * 100)vw;
		background: url('svg/slant-white.svg') no-repeat center transparent;
		background-size: 100% 100%;
		transform: rotate(180deg);
	}
	@media (--max) {
		padding-top: 214px;
		&::before { padding-top: 214px; }
	}
}
.mask-bottom-slant {
	padding-bottom: resolve(135 / 1200 * 100)vw;
	&::after {
		padding-top: resolve(135 / 1200 * 100)vw;
		background: url('svg/slant-white.svg') no-repeat center transparent;
		background-size: 100% 100%;
	}
	@media (--max) {
		padding-bottom: 214px;
		&::after { padding-top: 214px; }
	}
}
.mask-top-fade::before {
	padding-top: 225px;
	background-image: linear-gradient(to bottom, white 0%, white 15%, transparent 100%);
}
.mask-bottom-fade::after {
	padding-top: 225px;
	background-image: linear-gradient(to top, white 0%, white 15%, transparent 100%);
}
.mask-bottom-imgfade::after {
	top: 0;
	bottom: 0;
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * '.btn' buttons and so forth
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 4rem 0 5rem;
}
.btn-blank {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: 0;
	text-align: left;
	// cursor: pointer;
}
.btn {
	max-width: 100%;
	margin: 0 0 12px;
	padding: 15px 30px;
	background-color: $red;
	border: 0;
	border-radius: 300px;
	color: white;
	font: $semi-bold 1.8rem/1.4 $poppins;
	-webkit-font-smoothing: antialiased;
	text-align: center;
	transition: background-color 0.3s ease-in-out;
	cursor: pointer;
	a& {
		display: inline-block;
	}
	&:hover {
		background-color: $dark-red;
		color: white;
		text-decoration: none;
	}
	&.large {
		padding: 22px 50px;
		font: $semi-bold 2.2rem/1.4 $poppins;
	}
	&.white {
		background-color: white;
		color: $red;
		&:hover {
			color: $dark-red;
		}
	}
}
@media (--medium-up) {
	.buttons {
		flex-direction: row;
		&.row {
			.btn:not(:first-child) {
				margin-left: 30px;
			}
		}
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Helper styles for svg basics
 * .svg - this wrapper indicates we're overriding default
 * fill and stroke colors by applying "currentColor"
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.svg {
	[fill] { fill: currentColor; }
	[stroke] { stroke: currentColor; }
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * `.loader` helper for loading content
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 160px;
	height: 160px;
	margin: 0 auto;
	color: #bbb;
	font: 1.4rem/2 $poppins;
	transition: opacity 1s ease-in-out;
	opacity: 0;
	&.on {
		opacity: 1;
	}
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('svg/loader.svg') no-repeat center transparent;
		background-size: contain;
	}
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * helpers to visually hide elements on the page
 * mostly used for accessibility purposes
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.ghost {
	// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
	overflow: hidden;
	display: block;
	// scss-lint:disable ImportantRule
	position: absolute !important;
	// scss-lint:enable ImportantRule
	width: 1px;
	height: 1px;
	clip: rect(1px, 1px, 1px, 1px);
}

.visibility-hidden {
	visibility: hidden;
}

/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Helper to group JS for ease of development
 * @helper: .hidden-js
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.hidden-js {
	visibility: hidden;
}
