/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for .modal pattern
 * @module: .modal
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.modal {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: $nav-bar-height-small;
	right: 0;
	bottom: 0;
	left: 0;
	max-width: resolve($layout-max-width - $nav-bar-width-large);
	background-color: white;
	transition: opacity 0.25s ease-in-out;
	opacity: 0;
	pointer-events: none;
	z-index: 99999;
	&.open {
		opacity: 1;
		pointer-events: all;
		visibility: visible;
	}
	// .head {
	// 	position: relative;
	// 	z-index: 1;
	// }
	.close {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		width: 60px;
		height: 60px;
		background-color: $red;
		cursor: pointer;
		z-index: 2;
		&::after {
			content: '';
			display: block;
			width: 23px;
			height: 23px;
			background: url('svg/icon-close-x-white.svg') no-repeat center transparent;
			background-size: contain;
		}
	}
	.body {
		display: flex;
		flex-direction: column;
		flex: 1 0 100%;
		position: relative;
		z-index: 1;
	}
}

@media (--medium) {
	.modal {
		top: 0;
		left: $nav-bar-width-medium;
	}
}

@media (--large-up) {
	.modal {
		top: 0;
		left: $nav-bar-width-large;
	}
}
