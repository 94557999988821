/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * footer
 * includes logo, social media, links, copyright
 * and back to top
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.footer {
	// this little gimmick pushes the footer to the bottom of the page because of flex layout
	margin-top: auto;
	a {
		flex: 0 0 40px;
		color: $red;
		&:hover {
			color: $dark-red;
		}
	}
	// .ctas {}
	.logo {
		display: block;
		width: 90px;
		margin: 0 auto 30px;
		&::before {
			content: '';
			display: block;
			height: 96px;
			background-image: url('svg/sbcc-logo-footer.svg');
			background-size: contain;
		}
	}
	.contact {
		max-width: 540px;
		margin: 0 auto 5px;
		padding: 0 10px;
		.group {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			border-bottom: 2px solid $light-gray;
			&:first-child {
				border-top: 2px solid $light-gray;
			}
			&[aria-expanded="true"] {
				.toggle::after {
					background-image: url('svg/accordion-arrow-up.svg');
				}
				.content {
					display: block;
				}
			}
		}
		.title {
			flex: 0 0 calc(100% - 65px);
			width: calc(100% - 65px);
			margin: 0;
			padding: 0 0 0 20px;
			background-color: transparent;
			border: 0;
			font: $semi-bold 1.4rem/lh(14, 40) $poppins;
		}
		.toggle {
			flex: 0 0 32px;
			margin: 0 14px 0 0;
			padding: 0;
			border: 0;
			&::after {
				content: '';
				display: block;
				width: 19px;
				height: 12px;
				margin: 6px;
				background: url('svg/accordion-arrow-down.svg') no-repeat center transparent;
				background-size: contain;
			}
		}
		.content {
			display: none;
			flex: 1 0 100%;
			margin: 0 0 4px;
			padding: 0 20px;
			font: 1.4rem/lh(14, 32) $poppins;
		}
	}
	.utility-compliance-links {
		max-width: 540px;
		margin: 0 auto 30px;
		padding: 0 30px;
		font: $semi-bold 1.4rem/lh(14, 32) $poppins;
		list-style: none;
		a {
			color: inherit;
			&:hover {
				color: $dark-red;
			}
		}
	}
	.social-media {
		margin: 0 auto 30px;
	}
	.base {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 65px;
		background-color: $light-gray;
		font-size: 1.2rem;
		line-height: lh(12, 23);
	}
	.copyright {
		margin: 0 15px;
		p {
			margin: 0;
		}
	}
	.back-to-top {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 50px;
		height: 100%;
		background-color: $red;
		color: white;
		transition: background-color 0.3s ease-in-out;
		&:hover {
			background-color: $dark-red;
			color: white;
			&::after {
				transform: translateY(-4px);
			}
		}
		&::after {
			content: '';
			display: block;
			width: 16px;
			height: 20px;
			background: url('svg/up-arrow-white.svg') no-repeat center transparent;
			background-size: contain;
			transition: transform 0.3s ease-in-out;
		}
	}
}

@media (--medium-up) {
	.copyright {
		display: flex;
		p:last-child {
			display: flex;
			align-items: center;
			&::before {
				content: '|';
				display: block;
				margin: 0 24px;
			}
		}
	}
}

@media (--weird) {
	.footer {
		.top {
			display: flex;
			align-items: stretch;
			padding: 50px 0 50px 60px;
		}
		.logo {
			flex-basis: 155px;
			width: 155px;
			margin: 0 70px 0 0;
			&::before {
				height: 166px;
			}
		}
		.mid {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1 0 calc(100% - 225px);
			width: calc(100% - 225px);
		}
		.mid-2 {
			display: flex;
			align-items: flex-end;
		}
		.contact {
			display: flex;
			justify-content: space-between;
			max-width: none;
			margin: 0 0 15px;
			padding: 0;
			.group {
				flex: 1 0 29%;
				margin: 0 60px 0 0;
				border: 0;
				&:first-child {
					border: 0;
				}
			}
			.title {
				margin-bottom: 15px;
				padding: 0;
				line-height: 1;
			}
			.toggle {
				display: none;
			}
			.content {
				display: block;
				margin: 0;
				padding: 0;
				line-height: lh(14, 26);
			}
		}
		.utility-compliance-links {
			flex: 1 0 0;
			max-width: none;
			margin: 0;
			padding: 0;
			line-height: 1;
			ul {
				display: flex;
			}
			li:not(:first-child) {
				margin-left: 40px;
			}
		}
		.social-media {
			flex: 0 0 280px;
			width: 280px;
			margin: 0 60px 0 auto;
		}
		// .base {}
	}
}
