/** >>>>>
 * Basics
 * taken from normalize.css v7.0.0 and sanitize.css v5.0.0, but modified for client
*/

*,
::before,
::after {
	background-repeat: no-repeat;
	box-sizing: inherit;
}

::before,
::after {
	text-decoration: inherit;
	vertical-align: inherit;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main,
details,
menu {
	display: block;
}

a {
	background-color: transparent;
	color: $purple;
	text-decoration: none;
	transition: color $duration ease-in-out;
	-webkit-text-decoration-skip: objects;
	&:hover {
		color: $dark-purple;
		text-decoration: underline;
	}
	.main & {
		font-weight: $semi-bold;
	}
}

b,
strong {
	font-weight: $semi-bold;
}

/** >>>>>
 * NOTE: using `medium` font-sizes for old browser fallbacks
 * everything else is according to front end global style guide
*/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	a {
		color: $red;
		&:hover {
			color: $dark-red;
		}
	}
}

h1,
.h1 {
	margin: 0 0 5px;
	color: inherit;
	font-family: $poppins;
	font-size: 50px;
	font-size: 3.6rem; // scss-lint:disable DuplicateProperty
	font-weight: $semi-bold;
	line-height: lh(36, 43);
}
h2,
.h2 {
	margin: 0 0 15px;
	color: inherit;
	font-family: $poppins;
	font-size: 40px;
	font-size: 3rem; // scss-lint:disable DuplicateProperty
	font-weight: $semi-bold;
	line-height: lh(30, 36);
}
h3,
.h3 {
	margin: 0 0 6px;
	color: inherit;
	font-family: $poppins;
	font-size: 30px;
	font-size: 2.6rem; // scss-lint:disable DuplicateProperty
	font-weight: $semi-bold;
	line-height: lh(26, 32);
}
h4,
.h4 {
	margin: 0 0 5px;
	color: inherit;
	font-family: $poppins;
	font-size: 26px;
	font-size: 2rem; // scss-lint:disable DuplicateProperty
	font-weight: $semi-bold;
	line-height: lh(20, 26);
}
h5,
.h5 {
	margin: 0 0 9px;
	color: inherit;
	font-family: $poppins;
	font-size: 20px;
	font-size: 1.8rem; // scss-lint:disable DuplicateProperty
	font-weight: $semi-bold;
	line-height: lh(18, 24);
}
h6,
.h6 {
	margin: 0 0 9px;
	color: inherit;
	font-family: $poppins;
	font-size: 17px;
	font-size: 1.6rem; // scss-lint:disable DuplicateProperty
	font-weight: $semi-bold;
	line-height: lh(16, 22);
}
p {
	margin: 0 0 30px;
}
.intro {
	margin: 0 0 60px;
	font-size: 1.8rem;
	font-weight: $medium;
	line-height: lh(18, 28);
}
@media (--medium) {
	h1,
	.h1 {
		font-size: 5rem;
		line-height: lh(50, 60);
	}
	h2,
	.h2 {
		font-size: 4rem;
		line-height: lh(40, 46);
	}
	h3,
	.h3 {
		font-size: 3rem;
		line-height: lh(30, 36);
	}
	h4,
	.h4 {
		font-size: 2.6rem;
		line-height: lh(26, 32);
	}
	h5,
	.h5 {
		font-size: 2rem;
		line-height: lh(20, 26);
	}
	h6,
	.h6 {
		font-size: 1.7rem;
		line-height: lh(17, 22);
	}
	.intro {
		margin-bottom: 70px;
		font-size: 2rem;
		line-height: lh(20, 30);
	}
}
@media (--large-up) {
	h1,
	.h1 {
		font-size: 6.5rem;
		line-height: lh(65, 77);
	}
	h2,
	.h2 {
		font-size: 5rem;
		line-height: lh(50, 56);
	}
	h3,
	.h3 {
		font-size: 4rem;
		line-height: lh(40, 44);
	}
	h4,
	.h4 {
		font-size: 3.2rem;
		line-height: lh(32, 38);
	}
	h5,
	.h5 {
		font-size: 2.6rem;
		line-height: lh(26, 32);
	}
	h6,
	.h6 {
		font-size: 2rem;
		line-height: lh(20, 26);
	}
	.intro {
		margin-bottom: 70px;
		font-size: 2.6rem;
		line-height: lh(26, 40);
	}
}

nav ol,
nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

figure {
	margin: 1em 40px;
}

img {
	border-style: none;
	vertical-align: middle;
}

svg {
	vertical-align: middle;
	fill: currentColor;
	&:not(:root) {
		overflow: hidden;
	}
}

hr {
	overflow: visible;
	height: 0;
	box-sizing: content-box;
}

pre {
	font-family: monospace, monospace;
	font-size: 1em;
}

abbr[title] {
	text-decoration: underline dotted;
}

code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

dfn {
	font-style: italic;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

input,
optgroup,
select,
textarea {
	font: inherit;
	color: inherit;
}

select {
	text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	margin: 0;
	background-color: transparent;
	color: inherit;
	font: inherit;
	text-transform: none;
	-webkit-appearance: button;
}

fieldset {
	padding: 0.35em 0.75em 0.625em;
}

legend {
	display: table;
	max-width: 100%;
	padding: 0;
	color: inherit;
	white-space: normal;
	box-sizing: border-box;
}

textarea {
	overflow: auto;
}

summary {
	display: list-item;
}

// template {
// 	display: none;
// }

[hidden] {
	display: none;
}

q:before,
q:after {
	content: '';
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */

::-moz-selection {
	background-color: #b3d4fc; /* 1 */
	color: #000000; /* 1 */
	text-shadow: none;
}

::selection {
	background-color: #b3d4fc; /* 1 */
	color: #000000; /* 1 */
	text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */

/*
 * Change the alignment on media elements in all browers (opinionated).
 */

audio,
canvas,
iframe,
video {
	vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
	display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
	display: none;
	height: 0;
}

table {
	width: 100%;
	margin-bottom: 1em;
	border: 1px solid #979797;
	color: $text-color;
	border-collapse: collapse;
	border-spacing: 0;
	caption {
		margin: 1rem 0;
		padding: 0;
		font: $semi-bold 1.6rem/1.75 $poppins;
		text-align: left;
		text-transform: uppercase;
	}
	tr {
		padding: 10px 15px;
		border-bottom: 1px solid #979797;
	}
	th,
	td {
		padding: 10px 15px;
	}
	thead {
		background: $lighter-gray;
	}
	th {
		font: $semi-bold 1.4rem $poppins;
		text-align: left;
	}
}



/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */

/**
 * Remove the margin in Firefox and Safari.
 */

input,
optgroup,
select,
textarea {
	margin: 0;
	text-transform: none;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
	box-sizing: border-box; /* 1 */
	color: inherit; /* 2 */
	display: table; /* 1 */
	max-width: 100%; /* 1 */
	padding: 0; /* 3 */
	white-space: normal; /* 1 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */

textarea {
	overflow: auto; /* 1 */
	resize: vertical; /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
	box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
	-webkit-appearance: textfield; /* 1 */
	outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
	-webkit-appearance: button; /* 1 */
	font: inherit; /* 2 */
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */

/*
 * remove the tapping delay on clickable elements
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
	touch-action: manipulation;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */

/**
 * Change the cursor on busy elements (opinionated).
 */

[aria-busy="true"] {
	cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */

[aria-controls] {
	cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */

[aria-hidden="false"][hidden]:not(:focus) {
	clip: rect(0, 0, 0, 0);
	display: inherit;
	position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */

[aria-disabled] {
	cursor: default;
}
