/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for news pattern
 * @module: `.news-block`
 * NOTE: see `.media-blocks` section for responsive
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.news-block {
	display: block;
	margin: 0 0 50px;
	.buttons {
		margin: 0;
	}
	.media-block {
		margin: 0 0 25px;
	}
	.list {
		margin: 0 0 40px;
	}
	.btn {
		display: block;
		margin: 0 auto;
	}
}
@media (--small) {
	.news-block {
		overflow: hidden;
		margin-right: -15px;
		margin-left: -15px;
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media (--large-up) {
	.news-block {
		.track {
			display: flex;
		}
		.slide {
			flex: 1 0 0;
			&:not(:first-child) {
				margin-left: 40px;
			}
		}
		.media-block {
			display: block;
			margin-bottom: 0;
		}
		.img {
			margin: 0 0 25px;
		}
	}
}
