/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Styles for program list pattern
 * @module: `.program-list`
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.program-list {
	margin: 0 0 50px;
	ul {
		margin: 0;
		padding: 0;
		background-color: $lighter-gray;
		border: 1px solid #979797;
		list-style: none;
	}
	li {
		padding: 16px 20px;
		transition: background-color 0.3s ease-in-out;
		&:not(:last-child) {
			border-bottom: 1px solid #979797;
		}
		&:hover {
			background-color: #e4e6e4;
		}
		@media (--medium-up) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	h3 {
		margin: 0 0 3px;
		font: $semi-bold 1.8rem/lh(18, 25) $poppins;
		@media (--medium-up) {
			margin: 0 20px 0 0;
		}
	}
	h4 {
		margin: 0;
		font: $semi-bold 1.5rem/lh(15, 21) $poppins;
	}
}
