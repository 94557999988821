/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * header / banner
 * includes logo, main menu, search, and pipeline
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.header {
	position: relative;
	background-color: $red;
	background-image: linear-gradient(to top, #8d1527 0, #a6192e 20px);
	color: black;
	z-index: 999;
}

.header-bar {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: $nav-bar-height-small;
	margin: 0;
	padding: 0;
	.item {
		display: flex;
		align-items: center;
		position: relative;
		height: 100%;
		transition: background-color 0.3s ease-in-out;
		z-index: 1;
		&:hover {
			background-color: #711824;
		}
		&::after {
			content: '';
			display: block;
			width: 2px;
			height: 30px;
			background-color: black;
		}
		&:last-child,
		&:first-child {
			&::after {
				display: none;
			}
		}
	}
	.icon {
		display: flex;
		justify-content: center;
		align-content: stretch;
		align-items: center;
		width: 60px;
		height: 100%;
		margin: 0;
		padding: 0;
		border: 0;
		cursor: pointer;
		&::before {
			content: '';
			display: block;
			background: none no-repeat center transparent;
			background-size: contain;
		}
	}
	.logo {
		margin-right: auto;
		.icon {
			padding: 0 11px;
			&::before {
				width: 40px;
				height: 35px;
				background-image: url('svg/sbcc-logo-white.svg');
			}
		}
	}
	.main-menu {
		.icon::before {
			width: 24px;
			height: 20px;
			background-image: url('svg/menu-white.svg');
		}
	}
	.search {
		.icon::before {
			width: 22px;
			height: 22px;
			background-image: url('svg/search-white.svg');
		}
	}
	.pipeline {
		.icon::before {
			width: 17px;
			height: 23px;
			background-image: url('svg/pipeline-white-simple.svg');
		}
	}
	.dropdown {
		overflow: hidden;
		position: fixed;
		top: $nav-bar-height-small;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
		.menu-stable & {
			background-color: white;
		}
	}
	.slide {
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: white;
		transform: translate(0, -101%);
		transition: opacity 0.3s ease-in-out, transform 0s linear 0.31s;
		opacity: 0;
		.menu-stable & {
			// scss-lint:disable ImportantRule
			transform: translate(0, 0) !important;
			transition: opacity 0.3s ease-in-out !important;
			opacity: 1 !important;
			// scss-lint:enable ImportantRule
		}
		@media (--medium-up) {
			transform: translate(-101%, 0);
		}
	}

	/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	* navigation state
	*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

	.menu-main-open & {
		.main-menu {
			background-color: white;
			z-index: 2;
			.icon::before {
				width: 18px;
				height: 18px;
				background-image: url('svg/icon-close-x-red.svg');
				@media (--medium) {
					width: 22px;
					height: 22px;
				}
				@media (--large-up) {
					width: 27px;
					height: 27px;
				}
			}
			&::after {
				background-color: white;
			}
		}
		.dropdown-menu {
			pointer-events: all;
			.slide {
				transform: translate(0, 0);
				transition: transform 0.3s ease-in-out;
				opacity: 1;
			}
			.sections {
				.section {
					opacity: 1;
					&:nth-child(1) { transition: opacity 0.5s ease-in-out; }
					&:nth-child(2) { transition: opacity 0.5s ease-in-out 0.2s; }
					&:nth-child(3) { transition: opacity 0.5s ease-in-out 0.4s; }
					&:nth-child(4) { transition: opacity 0.5s ease-in-out 0.6s; }
					&:nth-child(5) { transition: opacity 0.5s ease-in-out 0.8s; }
				}
			}
			.base {
				transition: opacity 0.5s ease-in-out 1s;
				opacity: 1;
			}
		}
		@media (--medium-up) {
			.logo::after {
				background-color: transparent;
			}
		}
	}
	.menu-search-open & {
		.main-menu::after {
			background-color: transparent;
		}
		.search {
			background-color: white;
			z-index: 2;
			.icon::before {
				width: 18px;
				height: 18px;
				background-image: url('svg/icon-close-x-red.svg');
				@media (--medium) {
					width: 22px;
					height: 22px;
				}
				@media (--large-up) {
					width: 27px;
					height: 27px;
				}
			}
			&::after {
				background-color: white;
			}
		}
		.dropdown-search {
			pointer-events: all;
			.slide {
				transform: translate(0, 0);
				transition: transform 0.3s ease-in-out;
				opacity: 1;
			}
			label,
			.input {
				transition: opacity 0.3s ease-in-out 0.15s;
				opacity: 1;
			}
		}
	}

	/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	* main navigation
	*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

	.dropdown-menu {
		a {
			color: $text-color;
			&:hover {
				border-bottom: 2px solid $dark-red;
				color: $dark-red;
				text-decoration: none;
			}
		}
		.sections {
			margin: 0 0 25px;
			.section {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				border-bottom: 2px solid $red;
				transition: none;
				opacity: 0;
				&[aria-expanded="true"] {
					.toggle::after {
						background-image: url('svg/accordion-arrow-up.svg');
					}
					.content {
						display: block;
					}
				}
			}
			.title {
				flex: 0 0 calc(100% - 65px);
				width: calc(100% - 65px);
				margin: 0;
				padding: 0 15px;
				background-color: transparent;
				border: 0;
				font: $semi-bold 1.6rem/lh(16, 50) $poppins;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.toggle {
				flex: 0 0 32px;
				margin: 0 9px 0 0;
				padding: 0;
				border: 0;
				&::after {
					content: '';
					display: block;
					width: 19px;
					height: 12px;
					margin: 6px;
					background: url('svg/accordion-arrow-down.svg') no-repeat center transparent;
					background-size: contain;
					filter: brightness(0);
				}
			}
			.content {
				display: none;
				flex: 1 0 100%;
				margin: 0 0 20px;
				padding: 0 35px;
				font: 1.6rem/lh(16, 23) $poppins;
				li {
					margin: 0 0 16px;
				}
			}
		}
		.base {
			padding: 0 15px 15px;
			transition: none;
			opacity: 0;
			.btn {
				padding: 0 16px;
				font: $semi-bold 1.6rem/lh(16, 40) $poppins;
			}
			.utility-links {
				font: $semi-bold 1.6rem/lh(16, 23) $poppins;
				ul {
					display: flex;
					flex-wrap: wrap;
				}
				li {
					flex-basis: 50%;
					margin: 0 0 16px;
				}
			}
		}
	}

	/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	* main search
	*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

	.dropdown-search {
		.slide {
			padding: 50px 15px;
		}
		.slide-content {
			padding: 40px 0 0;
			border-top: 2px solid $red;
		}
		label,
		.input {
			transition: none;
			opacity: 0;
		}
		label {
			display: block;
			margin: 0 0 24px;
			font: $semi-bold 2.6rem/lh(26, 30) $poppins;
		}
		.input {
			display: flex;
			height: 60px;
			background: #f1f1f1;
			border: 1px solid black;
			border-radius: 3px;
			box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.5);
		}
		input {
			flex: 1 0 calc(100% - 56px);
			width: calc(100% - 56px);
			padding: 0 20px;
			background-color: transparent;
			border: 0;
		}
		.btn {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-basis: 56px;
			margin: 0;
			padding: 0;
			background-color: transparent;
			border-radius: 0;
			color: white;
			cursor: pointer;
			&::after {
				content: '';
				display: block;
				width: 26px;
				height: 26px;
				background: url('svg/search-icon-red.svg') no-repeat center transparent;
				background-size: contain;
				transition: filter 0.3s ease-in-out;
			}
			&:hover {
				&::after {
					filter: brightness(0.7);
				}
			}
		}
	}
}



@media (--small) {
	.layout {
		margin-top: $nav-bar-height-small;
	}
	.header {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		transition: top 0.3s ease-in-out;
		.header-up & {
			top: -$nav-bar-height-small;
		}
	}
}

@media (--medium-up) {
	.header {
		flex: 0 0 $nav-bar-width-medium;
		background-image: linear-gradient(to left, #8d1527 0, #a6192e 15px);
	}
	.header-bar {
		flex-direction: column;
		position: fixed;
		top: 0;
		left: 0;
		width: $nav-bar-width-medium;
		height: 280px;
		.item {
			flex-direction: column;
			width: 100%;
			&:not(:last-child)::after {
				content: '';
				display: block;
				width: 40px;
				height: 2px;
			}
		}
		.icon,
		.logo .icon {
			width: 100%;
			height: 66px;
		}
		.logo .icon {
			height: 76px;
		}
		.dropdown {
			top: 0;
			left: $nav-bar-width-medium;
		}
		.dropdown-menu {
			.sections {
				margin: 0 0 35px;
				// .section {}
				.title {
					padding: 0 35px;
				}
				.toggle {
					margin-right: 29px;
				}
				.content {
					padding: 0 55px;
				}
			}
			.base {
				display: flex;
				align-items: flex-start;
				padding: 0 35px 35px;
				.btn {
					margin: 0 40px 0 0;
				}
				.mid {
					flex: 1 0 auto;
				}
				.utility-links {
					margin: 0 0 4px;
					li {
						flex-basis: 33%;
					}
				}
			}
			.social-media {
				margin: 0;
			}
		}
		.dropdown-search {
			.slide {
				padding: 50px 35px;
			}
		}
	}
}

@media (--large-up) {
	.header {
		flex: 0 0 $nav-bar-width-large;
		background-image: linear-gradient(to left, #8d1527 0, #a6192e 20px);
	}
	.header-bar {
		width: $nav-bar-width-large;
		height: 342px;
		.item {
			&:not(:last-child)::after {
				width: 50px;
			}
		}
		.icon,
		.logo .icon {
			height: 84px;
		}
		.logo .icon::before {
			width: 50px;
			height: 43px;
		}
		.main-menu .icon::before {
			width: 36px;
			height: 30px;
		}
		.search .icon::before {
			width: 36px;
			height: 36px;
		}
		.pipeline .icon::before {
			width: 41px;
			height: 45px;
			background-image: url('svg/pipeline-white.svg');
		}
		.dropdown {
			left: $nav-bar-width-large;
		}
		.dropdown-menu {
			.slide {
				padding: 120px 70px 50px;
			}
			.sections {
				display: flex;
				margin: 0 0 15px;
				.section {
					display: block;
					flex: 1 0 0;
					padding: 25px 0 0;
					border-top: 5px solid $red;
					border-bottom: 0;
					&:not(:last-child) {
						margin: 0 40px 0 0;
					}
				}
				.title {
					margin: 0 0 40px;
					padding: 0;
					font: $semi-bold 2.6rem/lh(26, 30) $poppins;
					white-space: normal;
					&::after {
						display: none;
					}
				}
				.toggle {
					display: none;
				}
				.content {
					display: block;
					margin: 0;
					padding: 0;
				}
			}
			.base {
				align-items: center;
				padding: 0;
				.btn {
					margin: 0 30px 0 0;
				}
				.mid {
					display: flex;
					justify-content: space-between;
					align-items: center;
					// flex: 1 0 0;
				}
				.utility-links {
					margin: 0;
					line-height: 1;
					ul {
						flex-wrap: nowrap;
					}
					li {
						flex: 1 0 auto;
						margin: 0 10px 0 0;
					}
				}
			}
			.social-media {
				flex: 1 0 auto;
				max-width: 285px;
				margin: 0;
			}
		}
		.dropdown-search {
			.slide {
				padding: 120px 70px 50px;
			}
			.slide-content {
				border-top: 5px solid $red;
			}
			.input {
				height: 80px;
			}
			.btn {
				&::after {
					width: 36px;
					height: 36px;
				}
			}
		}
	}
}

// special cirumstance
@media (min-width: 1150px) {
	.header-bar {
		.dropdown-menu {
			.base {
				.btn {
					margin: 0 60px 0 0;
				}
				.utility-links {
					li {
						margin: 0 30px 0 0;
					}
				}
			}
		}
	}
}




/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Social Media icons
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.social-media {
	display: flex;
	justify-content: space-between;
	width: 300px;
	height: 40px;
	margin: 0 auto;
	a {
		display: block;
		flex: 0 0 40px;
		width: 40px;
		border-bottom: 0;
		color: $red;
		transition: filter 0.3s ease-in-out;
		-webkit-transform-style: preserve-3d;
		&:hover {
			border-bottom: 0 !important; // scss-lint:disable ImportantRule
			filter: brightness(0.7);
		}
		&::after {
			content: '';
			display: block;
			width: 40px;
			height: 40px;
			background: none no-repeat center transparent;
			background-size: contain;
		}
		&.facebook::after { background-image: url('svg/icon-social-facebook.svg'); }
		&.twitter::after { background-image: url('svg/icon-social-twitter.svg'); }
		&.instagram::after { background-image: url('svg/icon-social-instagram.svg'); }
		&.linkedin::after { background-image: url('svg/icon-social-linkedin.svg'); }
		&.youtube::after { background-image: url('svg/icon-social-youtube.svg'); }
		&.snapchat::after { background-image: url('svg/icon-social-snapchat.svg'); }
	}
}
