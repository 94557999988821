/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: .cta-cols-simple
 * Section featuring the School of Extended Learning
 * and differentiating the tuition-free and
 * fee-based class offerings.
 * Uses a CTAs: 2 Columns, Simple display object.
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.cta-cols-simple {
	background-color: $lighter-gray;
	.head {
		overflow: hidden;
		padding-top: 50px;
		padding-bottom: 90px;
		background-color: #5a4e86;
		color: white;
		z-index: 1;
		:last-child {
			margin-bottom: 0;
		}
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			pointer-events: none;
			z-index: 1;
		}
		&::before {
			height: calc(50% - resolve(135 / 1200 * 100 / 2)vw);
			background-color: #3e365d;
			@media (--max) {
				height: 19px;
			}
		}
		&::after {
			top: calc(50% - resolve(135 / 1200 * 100 / 2)vw - 1px);
			padding-top: resolve(135 / 1200 * 100)vw;
			background: url('svg/slant-3e365d.svg') no-repeat center transparent;
			background-size: 100% 100%;
			@media (--max) {
				top: 18px;
				padding-top: 214px;
			}
		}
		.block {
			position: relative;
			z-index: 2;
		}
	}
	.base {
		background-image: linear-gradient(to bottom, $lighter-gray 0, #fefefe 100%);
		z-index: 2;
	}
	.bits {
		display: flex;
		transform: translateY(-60px);
	}
	.bit {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 0 0;
		position: relative;
		width: 100%;
		margin: 0 0 0 10px;
		padding: 30px 15px 0;
		background-color: white;
		text-align: center;
		text-decoration: none;
		&:first-child {
			margin: 0;
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			right: 0;
			left: 0;
			height: 61px;
			margin-top: -1px;
			background-color: white;
		}
		&:hover {
			.arrow::after {
				transform: translateX(10px);
				filter: brightness(0.7);
			}
		}
	}
	// .pre {}
	h3 {
		margin: 0 0 13px;
		color: $red;
		font: $semi-bold 2.6rem/lh(26, 34) $poppins;
	}
	p {
		margin: 0 0 22px;
		color: $text-color;
		font: 1.7rem/lh(17, 27) $poppins;
	}
	// .post {}
	.arrow {
		line-height: 0;
		&::after {
			content: '';
			display: inline-block;
			width: 29px;
			height: 21px;
			background: url('svg/arrow-red.svg') no-repeat center transparent;
			background-size: contain;
			transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
		}
	}
}
@media (--small) {
	.cta-cols-simple {
		overflow: hidden;
		margin-right: -15px;
		margin-left: -15px;
		padding-right: 15px;
		padding-left: 15px;
		.bits {
			display: block;
		}
		.bit {
			margin: 0;
			padding: 30px 15px 40px;
			&::after {
				display: none;
			}
		}
		h3 {
			font: $semi-bold 2rem/lh(20, 32) $poppins;
		}
		p {
			font: 1.7rem/lh(17, 27) $poppins;
		}
	}
}
@media (--large-up) {
	.cta-cols-simple {
		.head {
			padding-top: 80px;
			padding-bottom: 115px;
		}
		.bit {
			padding: 30px 75px 0;
		}
		h3 {
			margin: 0 0 17px;
			font: $semi-bold 3.2rem/lh(32, 34) $poppins;
		}
		p {
			margin: 0 0 32px;
			font: 1.9rem/lh(19, 27) $poppins;
		}
	}
}



/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * @module: .cols-events
 * Event: 3 Up Feature showing events tagged as
 * "Support and Success"
 * Borrows from `.cta-cols-simple`, but
 * modified to be used as a events and carousel
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.cols-events {
	.bit {
		color: $text-color;
	}
	h3 {
		margin: 0 0 20px;
		color: $text-color;
		font: $semi-bold 1.8rem/1 $poppins;
		text-transform: uppercase;
		strong {
			display: block;
			font: $semi-bold 5.5rem/1 $poppins;
		}
	}
	p {
		margin: 0 0 18px;
		font: 1.5rem/lh(15, 25) $poppins;
	}
	.post {
		font: 1.4rem/lh(14, 22) $poppins;
	}
	.time {
		display: block;
	}
	.location {
		margin: 0 0 16px;
	}
	.buttons {
		margin-top: 0;
		margin-bottom: 0;
		background-color: white;
		.btn {
			margin: 0 auto;
		}
	}
}
@media (--medium) {
	.cols-events {
		h3 {
			margin: 0 0 20px;
			font-size: 2rem;
			strong {
				font-size: 6.5rem;
			}
		}
		p {
			font: 1.6rem/lh(16, 23) $poppins;
		}
		.post {
			font: 1.5rem/lh(15, 22) $poppins;
		}
	}
}
@media (--large-up) {
	.cols-events {
		h3 {
			margin: 0 0 20px;
			font-size: 2.6rem;
			strong {
				font-size: 7.5rem;
			}
		}
		p {
			margin: 0 0 18px;
			font: 1.8rem/lh(18, 27) $poppins;
		}
		.post {
			font: 1.6rem/lh(16, 22) $poppins;
		}
	}
}


/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Style adjusments for small carousel on .cols-events
*** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

@media (--small) {
	.cols-events {
		.base {
			background-image: linear-gradient(to top, #fefefe 105px, $lighter-gray 100%);
		}
		.carousel-sm {
			display: flex;
			.bit {
				height: 100%;
			}
			.carousel-indices {
				width: calc(100% + 50px);
				margin: 0 -25px;
				background-color: white;
			}
		}
		.buttons {
			margin-top: -80px;
			margin-right: -15px;
			margin-left: -15px;
			padding-top: 40px;
		}
	}
}
@media (--medium-up) {
	.cols-events {
		.carousel-sm {
			display: block;
			.track {
				display: flex;
			}
			.slide {
				flex: 1 0 33.333333333%;
				margin: 0 0 0 10px;
				&:first-child {
					margin: 0;
				}
			}
			.bit {
				height: 100%;
				margin: 0;
			}
		}
	}
}
